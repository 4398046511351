import React from "react"

const AboutTimeline = () => {
  return (
    <section className="about-timeline-component">
      <picture>
        <source media="(min-width: 1024px)" srcSet="/images/timeline_1920.jpg" />
        <source media="(min-width: 780px)" srcSet="/images/timeline_1024.jpg" />
        <source media="(min-width: 420px)" srcSet="/images/timeline_780.jpg" />
        <source media="(min-width: 320px)" srcSet="/images/timeline_420.jpg" />
        <img src="/images/timeline_320.png" alt="Linha do tempo" />
      </picture>
    </section>
  )
}

export default AboutTimeline
