import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Thumbnail from "../domain/thumbnail"

const TextRight = ({ title, text, button_label, button_link, new_tab, image, background_color }) => {
  return (
    <section className="text-right-left-component" style={{ backgroundColor: background_color }}>
      <div className="container is-padding-vertical-11" style={{ maxWidth: 1200 }}>
        <div className="columns is-gapless is-multiline">

          <div className="column is-6 is-12-touch is-hidden-touch col-image">
            <div className="is-padding-right-10 is-padding-horizontal-4-touch">
              <figure className="image is-5by4 text-image-round">
                <LazyLoadImage src={Thumbnail.textRightLeftImage(image)} alt={title} />
              </figure>
            </div>
          </div>

          <div className="column is-6 is-12-touch is-left-middle">
            <div className="is-padding-left-10 is-padding-horizontal-4-touch is-padding-bottom-8-touch has-text-white text-box">
              <h2 className="is-size-1 is-size-3-touch has-text-weight-bold is-margin-bottom-8">
                {title}
              </h2>
              <div className="is-margin-bottom-8">
                <div className="content content-white content-18" dangerouslySetInnerHTML={{ __html: text }} />
              </div>
              {button_label && button_link &&
                <div>
                  <a href={button_link} target={new_tab ? "_blank" : ""} rel={new_tab ? "noopener noreferrer" : ""} className="button is-rounded is-inverted" style={{ color: background_color }}>
                    <strong>{button_label}</strong>
                  </a>
                </div>}
            </div>
          </div>

          <div className="column is-6 is-12-touch is-hidden-desktop">
            <div className="is-padding-horizontal-4-touch">
              <figure className="image is-5by4 text-image-round">
                <LazyLoadImage src={Thumbnail.textRightLeftImage(image)} alt={title} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextRight
