import React from "react"
import LazyLoad from "react-lazy-load"

const AboutMission = () => {
  return (
    <section className="about-mission-component component-with-bg">
      <img src="/images/about-mission-bg.jpg" alt="" className="component-bg" />
      <div className="component-content">
        <div className="container is-padding-vertical-14" style={{ maxWidth: 1200, margin: '0 auto' }}>
          <div className="columns is-gapless is-multiline">
            <div className="column is-12-touch">
              <div className="is-padding-bottom-8-touch">
                <MissionItem
                  src="/images/icons/mission-arrow.svg"
                  title="Missão"
                  description="Transformar sua vida através de hábitos alimentares saudáveis." />
              </div>
            </div>
            <div className="column is-12-touch">
              <div className="is-padding-bottom-8-touch">
                <MissionItem
                  src="/images/icons/mission-people.png"
                  title="Propósito"
                  description="Oferecer produtos com qualidade comprovada, que prezem por uma vida mais saudável, para que nossos consumidores vivam mais e melhor." />
              </div>
            </div>
            <div className="column is-12-touch">
              <MissionItem
                src="/images/icons/mission-hand.svg"
                title="Valores"
                description="Nos comprometemos com a sua saúde e bem-estar. Para isso, valorizamos a justiça, a honestidade, o positivismo e, principalmente, a confiança em Deus!" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const MissionItem = ({ src, title, description }) => (
  <div className="is-padding-horizontal-4">
    <div className="columns is-gapless">
      <div className="column is-narrow">
        <div className="is-padding-right-4 has-text-centered-mobile">
          <LazyLoad>
            <img src={src} alt={title} />
          </LazyLoad>
        </div>
      </div>
      <div className="column has-text-centered-mobile">
        <h3 className="is-size-2 is-size-3-touch has-text-weight-bold">
          {title}
        </h3>
        <p className="is-font-size-18 is-line-height-20 has-text-weight-bold">
          {description}
        </p>
      </div>
    </div>
  </div>
)

export default AboutMission
