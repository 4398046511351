import React from "react"
import AwardsSlider from "./awards-slider"

const AboutText = ({ post }) => {
  return (
    <section className="about-text-component">

      <div className="columns is-gapless is-multiline is-text has-background-orange">
        <div className="column is-6 is-12-touch">

          <div className="container component-info">
            <div className="columns is-gapless">
              <div className="column is-6 is-12-touch">
                <div className="is-padding-top-11 is-padding-bottom-11 is-padding-horizontal-4-touch text-box">
                  <div className="is-margin-bottom-6">
                    <h1 className="is-size-1 has-text-white has-text-centered-mobile">
                      A Superbom
                    </h1>
                  </div>
                  <div className="content content-white content-18" dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
              </div>
            </div>
          </div>

          <div className="is-padding-top-11 is-padding-bottom-11 is-padding-horizontal-4-touch text-box" style={{ opacity: 0}}>
            <div className="is-margin-bottom-6">
              <h1 className="is-size-1 has-text-white has-text-centered-mobile">
                A Superbom
              </h1>
            </div>
            <div className="content content-white content-18" dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>

        </div>
        <div className="column is-6 is-12-touch has-background-dark">
          <AwardsSlider />
        </div>
      </div>

    </section>
  )
}

export default AboutText
