import React from "react"
import TextLeft from "./text-left"
import TextRight from "./text-right"

const TextRightLeft = ({ post, firstLeft }) => {
  if (!post.acf || !post.acf.contents) return null

  return post.acf.contents.map((content, index) => {
    if (index % 2 === 0) {
      return firstLeft ? <TextLeft key={index} {...content} /> : <TextRight key={index} {...content} />
    } else {
      return firstLeft ? <TextRight key={index} {...content} /> : <TextLeft key={index} {...content} />
    }
  })
}

export default TextRightLeft
