import React, { useEffect, useRef } from "react"
import { get } from "lodash"
import { useStaticQuery, graphql } from "gatsby"
import Slick from "react-slick"
import LazyLoad from "react-lazy-load"

const NextArrow = ({ className, style, onClick }) => (
  <button
    className={className}
    style={style}
    onClick={onClick}
  >
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right"
      className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
  </button>
)

const PrevArrow = ({ className, style, onClick }) => (
  <button
    className={className}
    style={style}
    onClick={onClick}
  >
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left"
      className="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>
  </button>
)

const AwardsSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressAward(
        filter: {status: {eq: "publish"}},
        sort: {order: ASC, fields: menu_order}
      ) {
        nodes {
          id
          post_id
          title
          content
          acf {
            image {
              sizes {
                _800_480_crop
              }
            }
          }
        }
      }
    }
  `)

  const componentRef = useRef(null)

  const resizeListener = () => {
    const { current } = componentRef
    const image = current.querySelector('.image')
    if (!image) return

    const height = current.offsetHeight - image.offsetHeight
    const arrows = current.querySelectorAll('.slick-arrow')
    const bottom = height / 2 - 10
    arrows.forEach(arrow => {
      arrow.style.bottom = `${bottom}px`
    })
  };

  useEffect(() => {
    resizeListener()

    window.addEventListener('resize', resizeListener)
    const timer = window.setInterval(resizeListener, 1000)

    return () => {
      clearInterval(timer)
      window.removeEventListener('resize', resizeListener)
    }
  }, []);

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  }

  const slides = data.allWordpressAward.nodes.map((node) => ({
    post_id: node.post_id,
    title: node.title,
    content: node.content,
    image: get(node, "acf.image.sizes._800_480_crop")
  }))

  return (
    <section className="awards-slider-component" ref={componentRef}>
      <div className="slick-main-dots">
        <Slick {...settings}>
          {slides.map(slide => (
            <div key={slide.post_id}>
              <div className="slider-item">
                <div className="is-padding-bottom-6">
                  <LazyLoad>
                    <figure className="image is-5by3">
                      <img src={slide.image} alt={slide.title} />
                    </figure>
                  </LazyLoad>
                </div>
                <div className="is-padding-horizontal-9 is-padding-horizontal-8-touch is-padding-vertical-4">
                  <img src="/images/premium-title.png" alt="Prêmio" />
                </div>
                <div className="info is-padding-horizontal-14 is-padding-horizontal-10-touch is-padding-bottom-6">
                  <div className="is-padding-bottom-2">
                    <h5 className="is-size-5 has-text-weight-bold has-text-white">
                      {slide.title}
                    </h5>
                  </div>
                  <div>
                    <div className="content content-white content-16" dangerouslySetInnerHTML={{ __html: slide.content }} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slick>
      </div>
    </section>
  )
}

export default AwardsSlider
